.contacts-sorting {
  margin-top: 0.8rem;
  display: flex;
}
.contacts-filter-item {
  background-color: grey;
  color: #fff;
  padding: 2px 27px;
  text-align: center;
  line-height: 30px;
  margin-right: 2rem;
}
.filter-sorting {
  font-size: 27px;
  font-weight: 400;
  color: #000;
  margin-right: 2rem;
}
.contacts-filter-item.active {
  color: blue;
}
.select_color {
  background-color: #fff;
  min-width: 218px;
}
.select_color .first_elem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: darkgray;
}
.tags_list {
  width: 124px;
  max-height: 250px;
  min-height: 88px;
  overflow-y: auto;
  min-width: 10rem;
  padding: 5px 8px;
  font-size: 1rem;
  color: #212529;
  text-align: right;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  margin-left: auto;
}
.tags_list > li {
  margin-bottom: 5px;
  cursor: pointer;
}
.position-relative {
  position: relative;
}
.tags_list::-webkit-scrollbar {
  display: none;
}
.scroll-hidden-column{
  scrollbar-width: none;
}

.bcard-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28.5px;
  width: 28.5px;
  border-radius: 1.5px;
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 2px;
  background-color: #009EEF;
}
