html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

.quill {
  .ql-container{
      display: none;
    }
}

.quill.enable {
  .ql-container {
    display: block;
  }
}
.ql-editor {
  max-height: 400px;
}
.quill-blur {
  display: none;
}

@media screen and (min-width: 0) and (max-width: 991px) {
  .edit-focus .quill {
    position: absolute;
    top: 0;
    height: 226px;
    overflow: hidden;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1;
  }
  .edit-focus .quill-blur {
    display: block;
    position: absolute;
    top: 226px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1;
  }
  .edit-focus .ql-editor {
    height: 160px;
    border-bottom: 1px solid #ccc;
  }
  .edit-focus .navbar {
    display: none;
  }
  .edit-focus .main-panel {
    padding: 0 !important;
  }
  .edit-focus .main-panel .main-content {
    min-height: unset !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}
