.logo-cols {
    margin-top: 32px;
    margin-bottom: 134px;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

@media (max-width: 768px) {
    .logo-cols {
        margin-bottom: 35px;
    }
}

.auth-form-container{
    max-width: 422px;
    display: flex;
    justify-content: center;
}

.auth-form {
    padding: 24px 32px;
    max-width: 422px;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
}

@media (max-width: 480px) {
   .auth-form-container{
    padding: 0px 5px;
   }
   .register-container{
    padding: 0px 5px;
   }
}

.error-alert {
    position: absolute; 
    top: -48px;
    width: 422px;
}

@media (max-width: 490px) {
    .error-alert {
        width: auto;
    }
}