.activity_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 5px;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98),
    box-shadow 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98);
  background-color: #fff;
  margin-bottom: 7px;
}

.activity_title {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  font-size: 14px;
  margin-left: 4px;
}
.activity_date {
  color: rgb(140, 140, 140);
  font-weight: 400;
  font-size: 14px;
  text-align: right;
  margin-right: 4px;
}
#diary_item .ant-popover-inner-content {
  overflow: scroll;
  max-height: 100px;
}
.daily_todo.todo_item .ant-picker:hover {
  border-color: #009eef;
}
