.home-empty-container {
    height: 32vh;
}

.home-empty-lead-container {
    height: 78vh;
}



.home-empty-contact-content {
    margin: 75px 0px 250px 45px;
    display: flex;
    align-items: center;
}

.home-empty-lead-content {
    margin: 0px 0px 0px 45px;
    display: flex;
    align-items: center;
}


.home-empty-image-container {
    margin-right: 25px;
}

.home-empty-image {
    width: 145px !important;
    height: 145px !important;
}

.home-empty-text {
    width: 400px;
    white-space: pre-wrap;
    text-align: left;
    font-size: 16px;
    color: black;
}

.home-empty-lead-text {
    width: 450px;
    white-space: pre-wrap;
    text-align: left;
    font-size: 16px;
    color: black;
}

/* Media Queries */

/* Tablet view */
@media (max-width: 900px) {
    .home-empty-image {
        width: 100px;
        height: 100px;
    }
    .home-empty-contact-content {
        margin: 25px 0px 210px 5px;
       
    }
    .home-empty-lead-content {
        margin: 0px ;
    }
    .home-empty-text {
        font-size: 12px;
    }
    .home-empty-lead-text {
        font-size: 12px;
    }
}

/* Mobile view */
@media (max-width: 600px) {
    .home-empty-content {
        flex-direction: column;
        align-items: flex-start;
        margin: 20px;
    }

    .home-empty-image-container {
        margin-right: 0;
        margin-bottom: 15px;
    }

    .home-empty-image {
        width: 75px;
        height: 75px;
    }

    .home-empty-text {
        width: 100%;
        font-size: 12px;
    }
}