.result_item {
  padding: 9px 16px;
  width: 100%;
  max-width: 272px;
  border-radius: 6px 6px 0 0;
}

#search_results .job {
  background-color: #fedec0 !important;
}

#search_results .contact {
  background-color: #85d3d3 !important;
}

#search_results .communication {
  background-color: #fef7cb !important;
}

#search_results .company {
  background-color: #b2e1fa !important;
}

#search_results .note {
  background-color: #eed5fe !important;
}

.default {
  background-color: #fbcac5;
}

.result_badge sup {
  box-shadow: unset;
  height: 16px;
  line-height: 16px;
  background: #f25041;
}

.job_badge sup {
  background-color: #ff9230 !important;
}

.contact_badge sup {
  background-color: #28B6B6 !important;
}

.communication_badge sup {
  background-color: #ffe753 !important;
  color: #000000 !important;
}

.company_badge sup {
  background-color: #009eef !important;
}

.note_badge sup {
  background-color: #c776ff !important;
}

.search-mentions-input {
  width: 250px;
  white-space: nowrap;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.expand-icon-search {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-right: 15px;
  margin-left: 15px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 5px;
}

.expand-icon-search:hover {
  background-color: #d0e8db;
}

.download-icon-search:hover {
  background-color: #d0e8db;
}


.table-search-container {
  width: 272px;
  margin-right: 15px;
  transition: all 0.8s ease;
}

@keyframes fadeInSlide {
  from {
    opacity: 0;
    left: 200px;
    transform: translateY(-10px);
    width: 272px;
  }

  to {
    opacity: 1;
    left: 0;
    transform: translateY(0);
    width: 100%;
  }
}


.table-search-container.expanded {
  position: absolute;
  left: 0;
  top: 130px;
  width: 100%;
  z-index: 100;
  transform: scale(1);
  margin: 0px;
  animation: fadeInSlide 0.5s ease-out forwards;
}


.table-search-container.collapse {
  transform: scale(0.1);
  opacity: 0;
}

.table-animation {
  animation: fadeInSlide 0.5s ease-out forwards;
}

@keyframes fadeOutSlide {
  from {
    opacity: 1;
    transform: translateY(0);
    width: 100%;
  }

  to {
    opacity: 0;
    transform: translateY(-10px);
    width: 272px;
  }
}

.table-search-container.collapsing {
  animation: fadeOutSlide 0.5s ease-out forwards;
}

.table-animation.collapsing {
  animation: fadeOutSlide 0.5s ease-out forwards;
}


.search-results {
  display: flex;
  width: auto;
  transition: all 1s ease;
}

.modal-click-link {
  cursor: pointer;
}

.modal-click-link:hover {
  color: #009eef;
}