.leads-drawer {
  position: relative;
  height: 200px;
  padding: 48px;
  overflow: hidden;
  text-align: center;
  border: 1px solid #ebedf0;
  border-radius: 2px;
}

.leads-page-title {
  margin: 15px;
}

.leads-page-table {
  transition: 2.5s ease-out;
}

.lead-rhs-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 4rem;
}

.lead-rhs-content-item,
.lead-rhs-content-action {
  display: flex;
  justify-content: space-between;
}

.company-title,
.job-title,
.job-tags,
.job-notes {
  font-size: 20px;
  margin-right: 0.7rem;
}

.job-url {
  font-size: 20px;
  text-decoration: underline;
}
#add_tag_input,
.job-title-input {
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.job-input:hover,
.job-title-input:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  z-index: 1;
}

.lead-rhs-content-checkbox {
  display: flex;
  flex-direction: column;
}

.lead-drawer-saving {
  position: absolute;
  bottom: 100px;
  display: flex;
}

.lead-drawer-saving-text {
  margin-left: 1rem;
}

.lead-drawer-saving-text:after {
  content: " .";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}
.add-lead-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.add-modal-actions .btn-cancel {
  box-shadow: inset 0 0 0 1px rgb(0 100 255);
  color: rgb(0 100 255);
}
.add-modal-actions .btn-save {
  background: linear-gradient(45deg, #8228d2, rgb(0 100 255) 50%, #00d7f0);
  color: #fff;
}

.job-filter-item {
  background-color: grey;
  color: #fff;
  padding: 2px 27px;
  text-align: center;
  line-height: 30px;
  margin-right: 2rem;
}
.add-lead-container .ant-card-body {
  padding: 15px;
}
.add-lead-container .job-title-input {
  width: auto;
  margin-top: 5px;
  margin-bottom: 5px;
}
.w-60 {
  width: 60%;
}
.add-job-applied {
  display: flex;
  width: 60%;
  justify-content: space-between;
}
.applied-switch {
  width: 180px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.applied-switch > span {
  border: 1px solid;
  padding: 3px;
}
.ant-picker-dropdown {
  z-index: 10000;
}
.contact-name {
  font-weight: 400;
  text-decoration: underline;
  color: dodgerblue;
  margin-left: 1rem;
}

.table-empty .ant-table-cell {
  border-bottom: 0px !important;
  border-top:1px solid #f0f0f0;
}

.ant-select-selection-search-input{
  padding-right: 15px !important;
}

.search-span-result {
  font-weight: 500;
  display: inline-block;
  max-width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
