/* START LAYOUT */
.verify_container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding-top: 2rem;
}
/* END LAYOUT */

/* START CONTENT */
.verify_link {
  color: #095494;
  text-decoration: underline;
}

.mr1 {
  margin-right: 1rem;
}
.form-item {
  margin-top: 1rem;
}

.ant-card.drag-card-option {
  margin-bottom: 10px;
  width: 330px;
}

.ant-card.drag-card-option .ant-card-body {
  padding: 5px 0px 5px 15px;
  display: flex;
  align-items: center;
}
.ant-card.drag-card-option.checked {
  border-color: lightgray;
}
/* END CONTENT */
