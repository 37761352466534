.title {
  margin-left: auto;
  margin-right: 2px;
}

.text {
  color: rgba(0, 0, 0, 0.68);
  line-height: 10px;
  font-size: 12px;
}
