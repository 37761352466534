.sticky-container {
  max-width: 270px;
  position: relative;
}

.sticky-outer {
  display: flex;
  padding-top: 92.5925926%;
  position: relative;

  width: 100%;
}

.sticky {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Shadow behind the sticky note */
.sticky:before {
  box-shadow: -2px 2px 15px 0 rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.25);
  content: "";
  width: 90%;
  left: 5px;
  height: 83%;
  position: absolute;
  top: 30%;
}

/* The sticky note itself */
.sticky-content {
  background: linear-gradient(
    180deg,
    rgba(187, 235, 255, 1) 0%,
    rgba(187, 235, 255, 1) 12%,
    rgba(170, 220, 241, 1) 75%,
    rgba(195, 229, 244, 1) 100%
  );
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Kalam", cursive;
  font-size: 1rem;

  clip-path: url(#stickyClip);
}
.delete_file {
  background-color: transparent;
  border: none;
}

/* Add responsiveness */
@media screen and (min-width: 640px) {
  .sticky:before {
    height: 79%;
    width: 90%;
  }
  .sticky-content {
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 768px) {
  .sticky:before {
    height: 75%;
    width: 90%;
  }
  .sticky-content {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .sticky:before {
    height: 73%;
    width: 90%;
  }
  .sticky-content {
    font-size: 1.875rem;
  }
}

.note-title {
  cursor: pointer;
  margin-bottom: 0;
  margin-right: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.notes-items {
  display: flex;
  align-items: center;
  text-align: center;
  max-width: 100%;
  overflow-x: scroll;
}

.ql-container{
  transition: min-height 0.3s ease-in-out;
  min-height: 20vh;
}

.ql-container.expanded{
  min-height: 75vh;
}
.note-container-quill-editor {
  .ql-container.expanded {
    min-height: 70vh;
  }
}

.note-container-quill-editor{
  .ql-container {
    padding-bottom: 10px;
  }
}

.note-quill-editor{
    .ql-editor {
        max-height: 75vh;
      }
}

.diary-quill-editor{
  .ql-container {
    padding-bottom: 10px;
  }
}

.mini-quill {
  .ql-container {
    display: block;
    border-radius: 5px;
    border: 1px solid #D3D3D3;
    min-height: 132px;
    height: 132px;
  }
  .ql-editor {
    max-height: 125px;
  }
}
.big-convo-quill {
  .ql-container{
    border: 1px solid #D3D3D3;
    border-radius: 5px;
    min-height: 75vh;
    height: 75vh;
    width: 100%;
    padding-bottom: 10px;
  }
  .ql-editor{
    max-height: 75vh;
  }

}
.mini-quill.focus{
  .ql-container{
    border: 1px solid #009eef;
  }
}
.convo-icon{
  width: 30px;
  height: 28px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 4px 0px 1px 3px;
  fill: grey;
  color: grey;
}
.convo-icon:hover{
  background-color: #eff0f0;
  fill: black;
  color: black;
}

.expand-icon{
  width: 30px;
  height: 28px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2px 6px 2px 6px;
  fill: grey;
  color: grey;
  cursor: pointer;
  margin-left: 10px;
}
.expand-icon:hover{
  background-color: #eff0f0;
  fill: black;
  color: black;
}
