.company-sorting {
  display: flex;
  margin: 20px;
}
.company-page-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: 20px;
}
.company-tags {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}
.border-right {
  border-right: 2px solid black;
  height: 22px;
}
.mr1 {
  margin-right: 1rem;
}
.contacts-filter-item {
  cursor: pointer;
  transition: 0.2s ease-in;
}
.contacts-filter-item:hover,
.contacts-filter-item.active {
  color: rgb(0 100 255);
}

.company-page-table th {
  padding: 15px 10px !important;
}
.chip {
  display: inline-flex;
  flex-direction: row;
  background-color: transparent;
  border: none;
  cursor: default;
  height: 36px;
  outline: none;
  padding: 0;
  font-size: 14px;
  color: #333333;
  font-family: "Open Sans", sans-serif;
  white-space: nowrap;
  align-items: center;
  border-radius: 16px;
  vertical-align: middle;
  text-decoration: none;
  justify-content: center;
}
.chip-head {
  display: flex;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  align-items: center;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
  width: 36px;
  color: #fff;
  height: 36px;
  font-size: 20px;
  margin-right: -4px;
}
.chip-content {
  cursor: inherit;
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  padding-right: 6px;
}
.chip-svg {
  color: #999999;
  cursor: pointer;
  margin: 4px 4px 0 -8px;
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 24px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  flex-shrink: 0;
}
.chip-svg:hover {
  color: #666666;
}

.company-page-modal input {
  margin: 5px;
  width: 160px !important;
}
.company-page-modal label {
  margin-right: 1rem;
}
.location-search .ant-select-selection-placeholder {
  color: #000;
}

#location_autocomplete .ant-select-selection-placeholder {
  color: #000 !important;
}

#company_modal .ant-tabs-content-holder {
  width: 100%;
}
.scroll-vertical {
  overflow-y: scroll;
  padding-right: 6px;
  max-height: 378px;
}

.note-action-buttons {
  display: flex;
  justify-content: flex-end;
  margin: 12px 24px 0 24px;
}

.note-chips {
  margin: 12px 0 0 0;
  padding: 9px 50% 3px 24px;
  width: 100%;
  border: 1px solid #f0f0f0;
}
